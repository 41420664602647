@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --tw-text-opacity: 1;
  }

  :target {
    scroll-margin-top: 6rem;
  }

  html {
    @apply grid;
    @apply grid-cols-1;
    @apply grid-rows-1;
    @apply min-h-full;
    @apply font-body;
    @apply bg-bg-base;
    @apply scroll-smooth;
  }

  body {
    @apply isolate;
    @apply flex;
    @apply flex-col;
    @apply min-h-full;
  }

  .container {
    @apply mx-auto;
    @apply px-6;
    @apply max-w-2xl;
    @apply w-full;
  }

  .container .container {
    @apply px-0;
  }

  .container.md {
    @apply max-w-md;
  }

  .container.sm {
    @apply max-w-sm;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

div.flicking-viewport {
  overflow: visible;
}
